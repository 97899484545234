import React from "react";
import styled from 'styled-components';

const FormStyles = styled.form`

    max-width: 800px;
    margin: auto;

    .section {
        border: 1px solid black;
        background-color: rgba(255,255,255,0.3);
    }

    .medium {
        font-family: Roboto-Medium;
    }

    .required {
        color: red;
        text-align: left;
        margin-left: 5px;
    }

    .requiredHeading  {
        float: right;
        color: red;
        text-transform: uppercase;
        font-size: 12px;
    }

    .formfield {
        display: grid;
        grid-template-columns: 1.2fr 2fr;
        margin: 10px;
        align-items: center;
    }

    .inputField {
        display: grid;
        grid-template-columns: 1fr 20px;
    }

    .formfield input {
        padding: 5px;
    }

    .formfield > label, span {
        text-align: right;
        margin-right: 10px;
    }

    input, select, textarea {
        border-radius: 0.25rem;
    }

    .checkbox {
        text-align: left;
    }
    
    .radioGroupItem {
        margin-right: 5px;
    }

    .buttonGroup {
        margin: 10px auto;
        text-align: center;
    }

    button {
        padding: 5px;
        border-radius: 0.25rem;
    }

    button:hover {
        cursor: pointer;
        opacity: 0.7;
    }

    .buttonGroup > button:nth-child(1) {
        margin-right: 15px;
    }

    .note {
        text-transform: uppercase;
        font-size: 12px;
    }

    p.note {
        text-align: right;
        padding: 0 20px;
    }

    .formFooter {
        text-align: center;
    }

    .submitted {
        display: flex;
    }

    @media screen and (max-width: 450px) {
        .formfield {
            grid-template-columns: 1fr;
        }

        .formfield > label, span {
            text-align: left;
            padding: 5px 0;
        }

        .inputField {
            grid-template-columns: 1fr 10px;
        }

        .checkbox {
            text-align: center;
        }

        p.note {
            text-align: center;
        }
    }

    @media screen and (max-width: 330px) {
        .requiredHeading  {
            float: none;
        }
    }
`;

export default function Form() {

  return (
    <FormStyles action="https://formspree.io/f/mgeplepe" method="POST">
        <p className="medium">Personal Information <span className='requiredHeading'>* Required fields</span></p>
        <div className='section'>
            <div className='formfield'>
                <label htmlFor="fullName">Full Name:</label>
                <div className='inputField'>
                    <input
                        type="text"
                        name="fullName"
                        id="fullName"
                        required
                    />
                    <span className='required'>*</span>
                </div>
            </div>
            <div className='formfield'>
                <label htmlFor="company">Company:</label>
                <div className='inputField'>
                    <input
                        type="text"
                        name="company"
                        id="company"
                        required
                    />
                    <span className='required'>*</span>
                </div>
            </div>
            <div className='formfield'>
                <label htmlFor="address">Address:</label>
                <div className='inputField'>
                    <input
                        type="text"
                        name="address"
                        id="address"
                    />
                </div>
            </div>
            <div className='formfield'>
                <label htmlFor="city">City:</label>
                <div className='inputField'>
                    <input
                        type="text"
                        name="city"
                        id="city"
                    />
                </div>
            </div>
            <div className='formfield'>
                <label htmlFor="stateProvince">State/Province:</label>
                <div className='inputField'>
                    <input
                        type="text"
                        name="stateProvince"
                        id="stateProvince"
                    />
                </div>
            </div>
            <div className='formfield'>
                <label htmlFor="zipCode">Zip/Postal Code:</label>
                <div className='inputField'>
                    <input
                        type="text"
                        name="zipCode"
                        id="zipCode"
                    />
                </div>
            </div>
            <div className='formfield'>
                <label htmlFor="country">Country:</label>
                <div className='inputField'>
                    <select name="country" id="country" required>
                        <option value="">Select one</option>
                        <option value="Canada">Canada</option>
                        <option value="USA">USA</option>
                        <option value="Other">Other</option>
                    </select>
                    <span className='required'>*</span>
                </div>
            </div>
            <div className='formfield'>
                <label htmlFor="email">Email address:</label>
                <div className='inputField'>
                    <input
                        id="email"
                        type="email"
                        name="_replyto"
                        required
                    />
                    <span className='required'>*</span>
                </div>
            </div>
           <div className='formfield'>
                <label htmlFor="phone">Phone:</label>
                <div className='inputField'>
                    <input
                        type="tel"
                        name="phone"
                        id="phone"
                    />
                </div>
           </div>
        </div>
        <p className="medium">Project Information</p>
        <div className='section'>
            <div className='formfield'>
                <label htmlFor="projectDetails">Project Details / Comments:</label>
                <div className='inputField'>
                    <textarea id="projectDetails" name="projectDetails" rows="5" cols="25" ></textarea>
                </div>
            </div>
            <div className='formfield'>
                <label htmlFor="translateFrom">Translate from:</label>
                <div className='inputField'>
                    <select name="translateFrom" id="translateFrom" multiple required>
                        <option value="English">English</option>
                        <option value="Afrikaans">Afrikaans</option>
                        <option value="Albanian">Albanian</option>
                        <option value="Arabic">Arabic</option>
                        <option value="Armenian">Armenian</option>
                        <option value="Azeri">Azeri</option>
                        <option value="Basque">Basque</option>
                        <option value="Belarusian">Belarusian</option>
                        <option value="Bosnian">Bosnian</option>
                        <option value="Bulgarian">Bulgarian</option>
                        <option value="Catalan">Catalan</option>
                        <option value="Chinese (Simplified)">Chinese (Simplified)</option>
                        <option value="Chinese (Traditional)">Chinese (Traditional)</option>
                        <option value="Croatian">Croatian</option>
                        <option value="Czech">Czech</option>
                        <option value="Danish">Danish</option>
                        <option value="Dutch">Dutch</option>
                        <option value="English">English</option>
                        <option value="Estonian">Estonian</option>
                        <option value="Farsi">Farsi</option>
                        <option value="Finnish">Finnish</option>
                        <option value="French">French</option>
                        <option value="French (Canadian)">French (Canadian)</option>
                        <option value="Georgian">Georgian</option>
                        <option value="German">German</option>
                        <option value="Greek">Greek</option>
                        <option value="Hebrew">Hebrew</option>
                        <option value="Hindi">Hindi</option>
                        <option value="Hungarian">Hungarian</option>
                        <option value="Indonesian">Indonesian</option>
                        <option value="Italian">Italian</option>
                        <option value="Japanese">Japanese</option>
                        <option value="Kazakh">Kazakh</option>
                        <option value="Korean">Korean</option>
                        <option value="Latvian">Latvian</option>
                        <option value="Lithuanian">Lithuanian</option>
                        <option value="Macedonian">Macedonian</option>
                        <option value="Malay">Malay</option>
                        <option value="Norwegian">Norwegian</option>
                        <option value="Polish">Polish</option>
                        <option value="Portuguese">Portuguese</option>
                        <option value="Portuguese (Brazil)">Portuguese (Brazil)</option>
                        <option value="Punjabi">Punjabi</option>
                        <option value="Romanian">Romanian</option>
                        <option value="Russian">Russian</option>
                        <option value="Serbian">Serbian</option>
                        <option value="Slovak">Slovak</option>
                        <option value="Slovenian">Slovenian</option>
                        <option value="Spanish">Spanish</option>
                        <option value="Spanish (US/Lat.Am)">Spanish (US/Lat.Am)</option>
                        <option value="Swahilli">Swahilli</option>
                        <option value="Swedish">Swedish</option>
                        <option value="Tamil">Tamil</option>
                        <option value="Thai">Thai</option>
                        <option value="Turkish">Turkish</option>
                        <option value="Ukrainian">Ukrainian</option>
                        <option value="Urdu">Urdu</option>
                        <option value="Uzbek">Uzbek</option>
                        <option value="Vietnamese">Vietnamese</option>
                        <option value="Other">Other</option>
                    </select>
                    <span className='required'>*</span>
                </div>
            </div>
            <p className="note">(To select multiple languages, hold down the CTRL key / OPTION key for Mac and click the languages)</p>
            <div className='formfield'>
                <label htmlFor="translateTo">Translate into:</label>
                <div className='inputField'>
                    <select name="translateTo" id="translateTo" multiple required>
                        <option value="Afrikaans">Afrikaans</option>
                        <option value="Albanian">Albanian</option>
                        <option value="Arabic">Arabic</option>
                        <option value="Armenian">Armenian</option>
                        <option value="Azeri">Azeri</option>
                        <option value="Basque">Basque</option>
                        <option value="Belarusian">Belarusian</option>
                        <option value="Bosnian">Bosnian</option>
                        <option value="Bulgarian">Bulgarian</option>
                        <option value="Catalan">Catalan</option>
                        <option value="Chinese (Simplified)">Chinese (Simplified)</option>
                        <option value="Chinese (Traditional)">Chinese (Traditional)</option>
                        <option value="Croatian">Croatian</option>
                        <option value="Czech">Czech</option>
                        <option value="Danish">Danish</option>
                        <option value="Dutch">Dutch</option>
                        <option value="English">English</option>
                        <option value="Estonian">Estonian</option>
                        <option value="Farsi">Farsi</option>
                        <option value="Finnish">Finnish</option>
                        <option value="French">French</option>
                        <option value="French (Canadian)">French (Canadian)</option>
                        <option value="Georgian">Georgian</option>
                        <option value="German">German</option>
                        <option value="Greek">Greek</option>
                        <option value="Hebrew">Hebrew</option>
                        <option value="Hindi">Hindi</option>
                        <option value="Hungarian">Hungarian</option>
                        <option value="Indonesian">Indonesian</option>
                        <option value="Italian">Italian</option>
                        <option value="Japanese">Japanese</option>
                        <option value="Kazakh">Kazakh</option>
                        <option value="Korean">Korean</option>
                        <option value="Latvian">Latvian</option>
                        <option value="Lithuanian">Lithuanian</option>
                        <option value="Macedonian">Macedonian</option>
                        <option value="Malay">Malay</option>
                        <option value="Norwegian">Norwegian</option>
                        <option value="Polish">Polish</option>
                        <option value="Portuguese">Portuguese</option>
                        <option value="Portuguese (Brazil)">Portuguese (Brazil)</option>
                        <option value="Punjabi">Punjabi</option>
                        <option value="Romanian">Romanian</option>
                        <option value="Russian">Russian</option>
                        <option value="Serbian">Serbian</option>
                        <option value="Slovak">Slovak</option>
                        <option value="Slovenian">Slovenian</option>
                        <option value="Spanish">Spanish</option>
                        <option value="Spanish (US/Lat.Am)">Spanish (US/Lat.Am)</option>
                        <option value="Swahilli">Swahilli</option>
                        <option value="Swedish">Swedish</option>
                        <option value="Tamil">Tamil</option>
                        <option value="Thai">Thai</option>
                        <option value="Turkish">Turkish</option>
                        <option value="Ukrainian">Ukrainian</option>
                        <option value="Urdu">Urdu</option>
                        <option value="Uzbek">Uzbek</option>
                        <option value="Vietnamese">Vietnamese</option>
                        <option value="Other">Other</option>
                    </select>
                    <span className='required'>*</span>
                </div>
            </div>
            <div className='formfield'>
                <label htmlFor="documentType">Type of document:</label>
                <div className='inputField'>
                    <select name="documentType" id="documentType" required>
                        <option value="">Select one</option>
                        <option value="Printed Material">Printed Material</option>
                        <option value="Website">Website</option>
                        <option value="Software">Software</option>
                        <option value="Other">Other</option>
                    </select>
                    <span className='required'>*</span>
                </div>
            </div>
            <div className='formfield'>
                <label htmlFor="subjectTopic">Subject/Topic:</label>
                <div className='inputField'>
                    <select name="subjectTopic" id="subjectTopic" required>
                        <option value="">Select one</option>
                        <option value="Accounting">Accounting</option>
                        <option value="Advertising/Marketing">Advertising / Marketing</option>
                        <option value="Aerospace">Aerospace</option>
                        <option value="Agriculture">Agriculture</option>
                        <option value="Architecture">Architecture</option>
                        <option value="Banking/Finance">Banking/Finance</option>
                        <option value="Biochemistry">Biochemistry</option>
                        <option value="Biology">Biology</option>
                        <option value="Botany">Botany</option>
                        <option value="Building/Construction">Building/Construction</option>
                        <option value="Casino/Gaming">Casino/Gaming</option>
                        <option value="Chemical">Chemical</option>
                        <option value="Computer Hardware">Computer Hardware</option>
                        <option value="Computer Software">Computer Software</option>
                        <option value="Computer (Other)">Computer (Other)</option>
                        <option value="Cosmetics/Beauty">Cosmetics/Beauty</option>
                        <option value="Dental/Vision/Hearing">Dental/Vision/Hearing</option>
                        <option value="Education">Education</option>
                        <option value="Electrical">Electrical</option>
                        <option value="Electronics">Electronics</option>
                        <option value="Employment">Employment</option>
                        <option value="Energy">Energy</option>
                        <option value="Energy (Canadian)">Energy (Canadian)</option>
                        <option value="Environmental">Environmental</option>
                        <option value="Fashion">Fashion</option>
                        <option value="Fisheries">Fisheries</option>
                        <option value="Food/Beverage">Food/Beverage</option>
                        <option value="Forestry">Forestry</option>
                        <option value="General">General</option>
                        <option value="Genetics">Genetics</option>
                        <option value="Geography">Geography</option>
                        <option value="Geology">Geology</option>
                        <option value="Geophysics">Geophysics</option>
                        <option value="Health Care">Health Care</option>
                        <option value="History">History</option>
                        <option value="Hospitality/Hotel">Hospitality/Hotel</option>
                        <option value="Immigration">Immigration</option>
                        <option value="Import/Export">Import/Export</option>
                        <option value="Industrial">Industrial</option>
                        <option value="Information Technology">Information Technology</option>
                        <option value="Insurance">Insurance</option>
                        <option value="Insurance (Brazil)">Insurance (Brazil)</option>
                        <option value="Labour related">Labour related</option>
                        <option value="Law/Legal">Law/Legal</option>
                        <option value="Life Sciences">Life Sciences</option>
                        <option value="Marine">Marine</option>
                        <option value="Mathematics/Statistics">Mathematics/Statistics</option>
                        <option value="Mechanical">Mechanical</option>
                        <option value="Medical Equipment">Medical Equipment</option>
                        <option value="Military">Military</option>
                        <option value="Mining">Mining</option>
                        <option value="Multimedia">Multimedia</option>
                        <option value="Music">Music</option>
                        <option value="Non-traditional Medicine">Non-traditional Medicine</option>
                        <option value="Nuclear">Nuclear</option>
                        <option value="Nutrition">Nutrition</option>
                        <option value="Paper/Pulp">Paper/Pulp</option>
                        <option value="Petroleum/Natural Gas/Coal">Petroleum/Natural Gas/Coal</option>
                        <option value="Pharmaceuticals">Pharmaceuticals</option>
                        <option value="Plastics/Rubber">Plastics/Rubber</option>
                        <option value="Political/Government">Political/Government</option>
                        <option value="Printing/Publishing">Printing/Publishing</option>
                        <option value="Real Estate">Real Estate</option>
                        <option value="Sports">Sports</option>
                        <option value="Stocks and Bonds">Stocks and Bonds</option>
                        <option value="Steel">Steel</option>
                        <option value="Telecommunications">Telecommunications</option>
                        <option value="Television/Radio/Film">Television/Radio/Film</option>
                        <option value="Textiles">Textiles</option>
                        <option value="Transportation">Transportation</option>
                        <option value="Travel/Tourism">Travel/Tourism</option>
                        <option value="Veterinary">Veterinary</option>
                        <option value="Video Gaming">Video Gaming</option>
                    </select>
                    <span className='required'>*</span>
                </div>
            </div>
            <div className='formfield'>
                <label htmlFor="wordCount">Number of words:</label>
                <div className='inputField'>
                    <input type="number" id="wordCount" name="wordCount" required/>
                    <span className='required'>*</span>
                </div>
            </div>
            <div className='formfield'>
                <label htmlFor="estimate">Is this value an estimate? (number of words)</label>
                <div className='inputField'>
                    <span className="checkbox"><input type="checkbox" id="estimate" name="estimate"/> Yes</span>
                </div>
            </div>
            <div className='formfield'>
                <label htmlFor="fileFormat">File format of documents to be translated:</label>
                <div className='inputField'>
                    <select name="fileFormat" id="fileFormat">
                        <option value="">Select one</option>
                        <option value="MS Word">MS Word</option>
                        <option value="MS Excel">MS Excel</option>
                        <option value="MS PowerPoint">MS PowerPoint</option>
                        <option value="MS Publisher">MS Publisher</option>
                        <option value="QuarkXpress">QuarkXpress</option>
                        <option value="InDesign">InDesign</option>
                        <option value="FrameMaker">FrameMaker</option>
                        <option value="Illustrator">Illustrator</option>
                        <option value="PDF">PDF</option>
                        <option value="HTML/Web">HTML/Web</option>
                        <option value="RTF/Text">RTF/Text</option>
                        <option value="WebWorks">WebWorks</option>
                        <option value="Hard Copy">Hard Copy</option>
                        <option value="Multiple Formats">Multiple Formats</option>
                        <option value="Other">Other</option>
                    </select>
                </div>
            </div>
            <div className='formfield'>
                <label htmlFor="webUrl">If HTML/Web, please provide the URL of the site to be localized (if available):</label>
                <div className='inputField'>
                    <input type="url" id="webUrl" name="webUrl" />
                </div>
            </div>
            <div className='formfield'>
                <span>Are source files available for upload?</span>
                <div className='inputField'>
                    <div>
                        <input className="radioGroupItem" type="radio" value="Yes" id="yes" name="filesForUpload" /><label className="radioGroupItem" htmlFor="yes">Yes</label>
                        <input className="radioGroupItem" type="radio" value="No" id="no" name="filesForUpload" /><label className="radioGroupItem" htmlFor="no">No</label>
                        <span className="radioGroupItem note">(If Yes, login information will be sent to you via email to access our FTP server)</span>
                    </div>
                </div>
            </div>
            <div className='formfield'>
                <label htmlFor="hearAbout">How did you hear about us?:</label>
                <div className='inputField'>
                    <select name="hearAbout" id="hearAbout">
                        <option value="">Select one</option>
                        <option value="Search Engine">Search Engine</option>
                        <option value="Brochure/Flyer">Brochure/Flyer</option>
                        <option value="Advertisement">Advertisement</option>
                        <option value="Online Directory">Online Directory</option>
                        <option value="Direct Mailing">Direct Mailing</option>
                        <option value="Trade Show/Convention">Trade Show/Convention</option>
                        <option value="Referral">Referral</option>
                        <option value="Other">Other</option>
                    </select>
                </div>
            </div>
        </div>
        <div className="buttonGroup">
            <button type="submit">Submit</button>
            <button type="reset">Reset Form</button>
        </div>
        <p className="formFooter">The information you submit will be kept confidential. We will not share your information with any 3rd party.</p>
    </FormStyles>
  )}